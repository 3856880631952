<template>
  <div>
    <div id="remarketings-page">
      <h1 class="h1-title">Remarketing</h1>
      <div
        v-if="
          !(
            (currentDealer && currentDealership.is_marketing_account && currentDealer.is_manager) ||
            currentDealer.has_marketing_access
          )
        "
      >
        <div class="flex-column">
          <div class="auto-margin shadow padding" style="font-size: 1.4em">
            <h1 style="text-align: center">Welcome To Remarketing</h1>
            <p class="vertical-spacing-top-half">
              You don't seem to be setup with the iAppraise Remarketing system. Contact Us to learn more.
            </p>
          </div>
        </div>
      </div>
      <div
        v-if="
          (currentDealer && currentDealership.is_marketing_account && currentDealer.is_manager) ||
          currentDealer.has_marketing_access
        "
      >
        <div class="flex-row margin-top-2">
          <secondary-button
            class="margin-right-05"
            title="New Remarketing Plan"
            icon="/assets/img/icon-plus.svg"
            v-on:click="showMarketingModal = true"
          />
          <search-bar
            class="margin-right flex-grow"
            availableFilterMode=""
            placeholder="Search for title"
            :emitActions="true"
            @applyFilter="applyFilter"
          />
        </div>
        <table class="results-table margin-top">
          <tr class="header">
            <th>Title</th>
            <th>Number of Appraisals</th>
            <th>Sent Time</th>
          </tr>
          <tr
            v-on:click="goToMarketingPlan(marketingPlan)"
            class="data-row selectable"
            v-for="marketingPlan in slicedRemarketings()"
            v-bind:key="marketingPlan.id"
          >
            <td>{{ marketingPlan.title ? marketingPlan.title : "-" }}</td>
            <td>{{ marketingPlan.form_count }}</td>
            <td>{{ formatDate(marketingPlan.sent_date) }}</td>
          </tr>
        </table>
        <paginator-bar
          v-model:page="page"
          :pageBounds="pageBounds()"
          :loadedItemsCount="marketingPlans.length"
          :loading="loading"
          :hasMorePages="nextUrl != null"
          :totalItemsCount="marketingPlanCount"
          @loadMore="getNextMarketingPlans()"
        />
      </div>

      <create-remarketing-modal
        @cancel="showMarketingModal = false"
        @submitted="createMarketing"
        v-if="showMarketingModal"
      />
    </div>
  </div>
</template>

<script>
import CreateRemarketingModal from "../../../components/Modals/v2/CreateRemarketingModal.vue";
import axios from "axios";
import { fetchMarketingPlans, createMarketingPlan } from "../../../api/Marketing.js";
import { axiosInstance } from "../../../api/axiosHandler";
import { mapActions, mapState } from "vuex";
import { pageBounds } from "../../../helpers/v2/utilityHelpers";

import SearchBar from "../../../components/Views/v2/SearchBar.vue";
import SecondaryButton from "../../../components/Buttons/v2/SecondaryButton.vue";
import PaginatorBar from "../../../components/Common/PaginatorBar.vue";
import _ from "lodash";

export default {
  name: "Marketings",
  components: { SecondaryButton, CreateRemarketingModal, SearchBar, PaginatorBar },
  data: function () {
    return {
      searchDict: {
        title: null,
        end_time_gt: null,
        end_time_lt: null
      },
      page: 1,
      axiosCancelToken: null,
      nextUrl: null,
      loading: false,
      marketingPlans: [],
      marketingPlanCount: 0,
      showMarketingModal: false
    };
  },
  methods: {
    createMarketing: function (title) {
      createMarketingPlan(title)
        .then(response => {
          this.marketingPlans.unshift(response);
          this.showMarketingModal = false;
        })
        .catch(error => {
          this.addError(error);
        });
    },
    fetchMarketingPlans: function () {
      if (this.axiosCancelToken) {
        if (this.axiosCancelToken.cancel) {
          this.axiosCancelToken.cancel("New Request");
        }
      }
      this.axiosCancelToken = axios.CancelToken.source();
      fetchMarketingPlans(this.searchDict, this.axiosCancelToken)
        .then(response => {
          this.marketingPlanCount = response.count;
          this.nextUrl = response.next;
          this.marketingPlans = response.results;
        })
        .catch(error => {
          if (!error.__CANCEL__) {
            this.addError(error);
          }
        });
    },
    slicedRemarketings: function () {
      let bounds = this.pageBounds();
      var toReturn = this.marketingPlans;

      return toReturn.slice(bounds.lowBound, bounds.highBound);
    },
    pageBounds() {
      return pageBounds(this.page);
    },
    getNextMarketingPlans() {
      if (this.nextUrl) {
        axiosInstance()
          .get(this.nextUrl, null)
          .then(response => {
            this.nextUrl = response.data.next;
            response.data.results.forEach(marketingPlan => {
              this.marketingPlans.push(marketingPlan);
            });
          })
          .catch(error => {
            this.addError(error);
          });
      }
    },
    formatDate(dateString) {
      if (dateString) {
        let convertedDate = new Date(dateString);
        return convertedDate.toDateString() + ", " + convertedDate.toLocaleTimeString();
      } else {
        return "Not Sent Yet";
      }
    },
    goToMarketingPlan: function (marketingPlan) {
      this.$router.push({ name: "Marketing", params: { id: marketingPlan.id } });
    },
    applyFilter: function (filter) {
      if (filter.filter.key == "quick_search") {
        this.searchDict.title = filter.filter.value;
      } else {
        var newFilters = _.cloneDeep(this.filters);
        newFilters[filter.filter.key] = filter.filter.value;
        this.filters = newFilters;
      }
    },
    removeFilter: function (filter) {
      delete this.filters[filter.key];
    },
    clearFilters: function () {
      this.searchDict.title = null;
      this.searchDict.end_time_gt = null;
      this.searchDict.end_time_lt = null;
    },
    ...mapActions({
      addError: "errorStore/addError"
    })
  },
  computed: {
    ...mapState({
      currentDealer: state => state.dealershipStore.currentDealer,
      currentDealership: state => state.dealershipStore.currentDealership
    })
  },
  watch: {
    searchDict: {
      handler: function () {
        this.fetchMarketingPlans();
      },
      deep: true
    }
  },
  mounted: function () {
    this.fetchMarketingPlans();
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/v2/_variables.scss";
@import "@/scss/v2/base.scss";

#remarketings-page {
  padding-top: $PADDING_X2_05;
  padding-left: $PADDING_X4;
}

.splash-img {
  width: 326px;
  height: 170px;
  object-fit: cover;
}
</style>
